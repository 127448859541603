import React from "react"
import "../../../i18n.js";
import { useTranslation } from 'react-i18next'
import Layout from "../../components/layout"
import Seo from "../../components/seo"
// import Popup from "../../components/popup";
import BlogBanner from "../../components/blog-banner/blogBanner";
import bookSpotBanner from '../../images/about-banner.gif';

const BookASpotPage = () => {
  // const [openModal, setOpenModal] = useState(true);

  const { t } = useTranslation();

  // const onClose = () => setOpenModal(false);

  /* useEffect(() => {
    if (openModal) {
      document.body.classList.add('h-screen', 'overflow-hidden');
    } else {
      document.body.classList.remove('h-screen', 'overflow-hidden');
    }
  }, [openModal]); */

  return (
    <Layout className="bg-blue">
      {/* <Popup isOpen={openModal} onClose={onClose} /> */}
      <Seo title={t("metaBookASpotTitle")} description={t("metaBookASpotDescription")} />

      {/* <div className="p-5 text-center w-full flex items-center">
        <iframe title="tio | Space" className="mx-auto" src="https://docs.google.com/forms/d/e/1FAIpQLSel1LjHXIR5ik7OtM0_OfqXls0f7cp9n9PZTe24fv-Ix8xhaw/viewform?embedded=true" width="640" height="1169" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      
    </div> */}

      <div className="mt-28 lg:mt-32">
        <div>
          <h1 className="block mx-5 md:mx-16 text-6xl md:text-8xl lg:text-9xl font-extrabold text-white">{t("bookaSpotTitle")}</h1>
          {/*   <div className="flex overflow-hidden text-cream text-6xl md:text-8xl lg:text-9xl font-light italic">
            <div className="flex items-center flex-none min-w-full animate-scroll-left">
              <p>{t("welcomeTickerTitle")}</p>
            </div>
            <div className="flex items-center flex-none min-w-full animate-scroll-left">
              <p>{t("welcomeTickerTitle")}</p>
            </div>
          </div> */}

          <div className="overflow-hidden flex text-cream text-6xl md:text-8xl lg:text-9xl font-light italic">
            <div className="flex items-center flex-none min-w-full animate-scroll-left">
              <div className="flex items-center mx-4">
                <p>{t("welcomeTickerTitle")}</p>
              </div>
              <div className="flex items-center mx-4">
                <p>{t("welcomeTickerTitle")}</p>
              </div>
            </div>
            <div className="flex items-center flex-none min-w-full animate-scroll-left">
              <div className="flex items-center mx-4">
                <p>{t("welcomeTickerTitle")}</p>
              </div>
              <div className="flex items-center mx-4">
                <p>{t("welcomeTickerTitle")}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-16 px-5 md:px-72 lg:px-96">
          <p className="text-cream text-center text-2xl lg:text-3xl">
            <span className="font-bold">{t("bookaSpotSection1Description1")}</span>{" "}
            {t("bookaSpotSection1Description2")}
          </p>
        </div>

        <div className="mt-32 lg:mt-64 pl-5 md:pl-16 pr-28">
          <p className="text-cream text-2xl lg:text-3xl">{t("bookaSpotSection2Description1")}{" "}
            <span className="font-bold">{t("bookaSpotSection2Description2")}</span>
          </p>
        </div>

        <div className="flex justify-end">
          <div className="mt-32 lg:mt-64 max-w-xs md:max-w-md lg:max-w-xl pr-5 md:pr-16">
            <p className="text-cream text-2xl lg:text-3xl text-right">
              {t("bookaSpotFormDescription")}
            </p>
          </div>
        </div>

        <div className="flex justify-center mt-32 md:mt-16 lg:mt-6">
          <svg className="animate-bounce text-center fill-current text-lightBlue" width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.35938 -4.76837e-07V12.8438L13.2969 7.89062L14.5156 9.09375L7.5 16.0938L0.5 9.09375L1.6875 7.89062L6.64063 12.8438V-4.76837e-07H8.35938Z" />
          </svg>
        </div>

        {/* <form className="mt-16 md:mt-32 max-w-xs md:max-w-xl lg:max-w-2xl mx-auto space-y-8 text-white">
          <input type="text" placeholder={t("nameSurnamePlaceholder")} required
            className="w-full bg-transparent rounded-none outline-none border border-white placeholder-white placeholder-opacity-80 text-lg p-2.5"
          />
           <input type="email" name="email" placeholder={t("emailPlaceholder")} required
            className="w-full bg-transparent rounded-none outline-none border border-white placeholder-white placeholder-opacity-80 text-lg p-2.5"
          />
           <input type="text" placeholder={t("phonePlaceholder")} required
            className="w-full bg-transparent rounded-none outline-none border border-white placeholder-white placeholder-opacity-80 text-lg p-2.5"
          />
           <textarea type="text" placeholder={t("professionPlaceholder")} required
            className="resize-marker w-full bg-transparent rounded-none outline-none border border-white placeholder-white placeholder-opacity-80 text-lg p-2.5"
          />
           <textarea type="text" placeholder={t("socialPlaceholder")} required
            className="resize-marker w-full bg-transparent rounded-none outline-none border border-white placeholder-white placeholder-opacity-80 text-lg p-2.5"
          />
           <textarea type="text" placeholder={t("otherPlaceholder")}
            className="resize-marker w-full bg-transparent rounded-none outline-none border border-white placeholder-white placeholder-opacity-80 text-lg p-2.5"
          />
          <input type="submit" value={t("bookaSpotFormCTA")} className="bg-white text-blue text-lg px-8 py-3 cursor-pointer"/>
        </form> */}

        <div className="p-5 text-center w-full flex items-center">
          <iframe title="tio | Space" className="mx-auto" src="https://docs.google.com/forms/d/e/1FAIpQLSel1LjHXIR5ik7OtM0_OfqXls0f7cp9n9PZTe24fv-Ix8xhaw/viewform?embedded=true" width="640" height="1169" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
        </div>

        <div className="flex flex-col justify-center mt-64 mb-5 md:mb-32 px-5 md:px-16">
          <div className="w-full">
            <img src={bookSpotBanner} alt="tio" className="w-full" />
          </div>

          <div className="flex justify-between mt-6">
            <p className="text-white text-sm lg:text-xl">{t("ourOffice")} →</p>
            <p className="text-white italic text-sm lg:text-xl">{t("locality")}</p>
          </div>
        </div>

      </div>
      <BlogBanner />
    </Layout>)
}

export default BookASpotPage
