import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import '../../../i18n.js';
import { useTranslation } from "react-i18next"
import Blog from "./blog"

const BlogBanner = () => {
  const { t } = useTranslation()

  const { allWpPost } = useStaticQuery(graphql
    `query{
        allWpPost(
          filter: { language: { slug: { eq: "en" } } }
          sort: { order: DESC, fields: [date] }
        ) {
          edges {
            node {
              id
              uri
              excerpt
              content
              title
              author {
                node {
                  id
                  avatar {
                    url
                  }
                  name
                  uri
                  description
                }
              }
              date(formatString: "MMMM DD, YYYY")
              featuredImage {
                node {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        quality: 100
                        placeholder: TRACED_SVG
                        layout: FULL_WIDTH
                      )
                    }
                  }
                }
              }
            }
          }
        }
      }`
  )

  return (
    <div className="bg-blue text-white w-full py-24 lg:py-36">
      <div className="text-right">
        <Link to="/blog" className="px-5 md:px-10 lg:px-16 text-cream md:text-lightBlue text-2xl hover:underline">
            {t("blogBannerLink")} ↗
        </Link>
      </div>

      <div className="mt-6 md:mb-1.5 lg:mb-16">
        <p className="mx-5 md:mx-10 lg:mx-16 text-6xl md:text-8xl lg:text-9xl font-extrabold">
          {t("blogBannerTitle")}
        </p>
       {/*  <div className="ticker-wrap-v2 bg-transparent text-white text-6xl md:text-8xl lg:text-9xl font-light italic">
          <div className="ticker z-10">
            <div className="ticker-item w-auto mx-16">
              <p>{t("blogBannerTitleTicker")}</p>
            </div>
            <div className="ticker-item w-auto mx-16">
              <p>{t("blogBannerTitleTicker")}</p>
            </div>
            <div className="ticker-item w-auto mx-16">
              <p>{t("blogBannerTitleTicker")}</p>
            </div>
            <div className="ticker-item w-auto mx-16">
              <p>{t("blogBannerTitleTicker")}</p>
            </div>
            <div className="ticker-item w-auto mx-16">
              <p>{t("blogBannerTitleTicker")}</p>
            </div>
          </div>
          <div className="ticker z-10">
            <div className="ticker-item w-auto mx-16">
              <p>{t("blogBannerTitleTicker")}</p>
            </div>
            <div className="ticker-item w-auto mx-16">
              <p>{t("blogBannerTitleTicker")}</p>
            </div>
            <div className="ticker-item w-auto mx-16">
              <p>{t("blogBannerTitleTicker")}</p>
            </div>
            <div className="ticker-item w-auto mx-16">
              <p>{t("blogBannerTitleTicker")}</p>
            </div>
            <div className="ticker-item w-auto mx-16">
              <p>{t("blogBannerTitleTicker")}</p>
            </div>
          </div>
        </div> */}

        <div className="flex overflow-hidden text-white text-6xl md:text-8xl lg:text-9xl font-light italic">
          <div className="flex items-center flex-none min-w-[125%] md:min-w-full animate-scroll-left">
            <p>{t("blogBannerTitleTicker")}</p>
          </div>
          <div className="flex items-center flex-none min-w-[125%] md:min-w-full animate-scroll-left">
            <p>{t("blogBannerTitleTicker")}</p>
          </div>
        </div>
      </div>
      <div className="md:flex p-5 md:p-10 lg:p-16">
        {allWpPost.edges.slice(0, 2).map(post => {
          const featuredImage = {
            data: post.node.featuredImage?.node?.localFile?.childImageSharp
              ?.gatsbyImageData,
            alt: post.node.featuredImage?.node?.alt || ``,
          }

          return (
            <Blog
              key={post.node.id}
              title={post.node.title}
              uri={post.node.uri}
              featuredImage={featuredImage}
            />
          )
        })}
      </div>
    </div>
  )
}
export default BlogBanner

