import { Link } from "gatsby"
import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import "../../../i18n.js";
import { useTranslation } from "react-i18next"
import parse from "html-react-parser"

const Blog = ({ title, uri, featuredImage }) => {
  const { t } = useTranslation()

  return (
    <div className="w-full md:w-1/2 p-2">
      <Link
        to={uri}
        itemProp="url"
        className="block text-white font-bold text-xl text-left py-5"
      >
        {featuredImage?.data && (
          <GatsbyImage
            className="block w-full"
            image={featuredImage?.data}
            alt={featuredImage.alt}
            style={{ marginBottom: 50 }}
          />
        )}
        {parse(title)}
      </Link>

      <Link
        className="block text-white font-normal text-xl text-left hover:underline"
        to={uri}
      >
        → {t("blogLink")}
      </Link>
    </div>
  )
}

export default Blog
